import request from '../utils/request';
// import uploadFile from './aboge_request.js'  //上传
import {serverPath} from './config.js';

export function login(uname,upass){
    return request({
        url: serverPath + '/UserAdminLogin',
        method: 'get',
        params: {uname:uname,upass:upass}
    });
}

export function getUserFengXiView(rank){   //查询可视化统计 返回数据结构 用户分析视图
    return request({
        url: serverPath + '/getUserFengXiView',
        method: 'get',
        params: {rank:rank}
    });
}

export function getHuiYuanCount(){   //返回资料查看次数最多的30份， 一个月内
    return request({
        url: serverPath + '/getHuiYuanCount',
        method: 'get',
        params: {}
    });
}

export function getZiLiaoCountView(){   //查询可视化统计 返回数据结构  资料浏览次数分析
    return request({
        url: serverPath + '/getZiLiaoCountView',
        method: 'get',
        params: {}
    });
}



export function getZiLiaoCount(){   //返回资料查看次数最多的30份， 一个月内
    return request({
        url: serverPath + '/getZiLiaoCount',
        method: 'get',
        params: {}
    });
}



/**
 * @param {Object} UserId  用户id
 * @param {Object} rank   等级
 * @param {Object} startVipdate  会员开始时间
 * @param {Object} shangjiUserId   //上级
 * @param {Object} page
 */
export function getUserDataList(UserId,rank,startVipdate,shangjiUserId,page){   //查询用户列表
    return request({
        url: serverPath + '/getUserDataList',
        method: 'get',
        params: {UserId:UserId,rank:rank,startVipdate:startVipdate,shangjiUserId:shangjiUserId,page:page}
    });
}



export function TGJiFenShenHe(UserId){  // 通过数据审核
    return request({
        url: serverPath + '/Admin_TGJiFenShenHe',
        method: 'get',
		params: {UserId:UserId}
    });
}

export function getUserZiLiaoLiuLanData(UserId,page){  // 查询用户浏览资料记录
    return request({
        url: serverPath + '/getUserZiLiaoLiuLanData',
        method: 'get',
		params: {UserId:UserId,page:page}
    });
}


export function ExidUpdateData(UserId,phoneNumber,rank,vipdate){  // 修改会员数据
	if(!phoneNumber){
		phoneNumber = '';
	}
	return request({
        url: serverPath + '/UpdateData',
        method: 'get',
		params: {UserId:UserId,phoneNumber:phoneNumber,rank:rank,vipdate:vipdate}
    });
}

export function getNewsData(page){  // 获取新闻列表全部数据
    return request({
        url: serverPath + '/getNewsData',
        method: 'get',
		params: {page:page}
    });
}


export function deleteNewsItem(id){  // 获取新闻列表全部数据
    return request({
        url: serverPath + '/deleteNewsItem',
        method: 'get',
		params: {id:id}
    });
}

export function deleteTimingnewsItem(id){  // 获取新闻列表全部数据
    return request({
        url: serverPath + '/deleteTimingnewsItem',
        method: 'get',
		params: {id:id}
    });
}


export function AdmingetFileNameList(path){  // 获取文件列表
	if(!path){
		path='';
	}
    return request({
        url: serverPath + '/AdmingetFileNameList',
        method: 'get',
		params: {path:path}
    });
}


export function getTimingNewsData(page){  // 获取新闻列表全部数据
    return request({
        url: serverPath + '/getTimingNewsData',
        method: 'get',
		params: {page:page}
    });
}

export function getTimingNewsIndex(){  // 获取新闻列表全部数据
    return request({
        url: serverPath + '/TimingNewsIndex',
        method: 'get'
    });
}



export function getAdminuploadImgFilePath(title,filePath){  // 发布新闻 并且上传图片
	return serverPath + '/AdminuploadImgFile?title=' + title + '&filePathName=' + filePath;
}

export function getAdminuploadImgFileTimingNewsFilePath(title,filePath){  // 发布新闻 并且上传图片
	return serverPath + '/AdminuploadImgFileTimingNews?title=' + title + '&filePathName=' + filePath;
}



export function getuploadImgFengMianPath(UserId){  // 获取文件上传地址
	return serverPath + '/uploadImgFengMian?UserId=' + UserId;
}

export function getuploadServiceImg(UserId){  // 获取文件上传地址
	return serverPath + '/uploadServiceImg?UserId=' + UserId;
}




export function AdminChangePassword(uname,upass1,upass2){   //后台用户修改密码
	return request({
		url: serverPath + '/AdminChangePassword',
		method: 'get',
		params: {uname:uname,upass1:upass1,upass2:upass2}
	});
}

export function DeleteZiLiaoMonth(){   //删除一个月以前的资料浏览记录
	return request({
		url: serverPath + '/DeleteZiLiaoMonth',
		method: 'get',
		params: {}
	});
}



//合伙人管理模块接口
export function getFenshenUserList(){   //合伙人分身用户查询
	return request({
		url: serverPath + '/getFenshenUserList',
		method: 'get',
		params: {}
	});
}

export function AddFenshenUser(UserId,upass){   //合伙人分身用户添加
	return request({
		url: serverPath + '/AddFenshenUser',
		method: 'get',
		params: {UserId:UserId,upass:upass}
	});
}

export function DeleteFenshenUser(id){   //合伙人分身用户删除
	return request({
		url: serverPath + '/DeleteFenshenUser',
		method: 'get',
		params: {id:id}
	});
}


export function getGunDonTuData(UserId){   //滚动图列表查询
	return request({
		url: serverPath + '/getGunDonTuData?UserId=' + UserId,
		method: 'get'
	});
}
export function deleteGunDonTuData(id){   //滚动图列表查询
	return request({
		url: serverPath + '/deleteGunDonTuData',
		method: 'get',
		params: {id:id}
	});
}









